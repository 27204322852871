.container input {
    /* color: #fff !; */
}

.category div, .category input, .search input, .category label, .search label, .category svg {
    color: #fff !important;
}

.category ::before {
    border-color: #fff !important;
}


.container .fieldset {
    border-color: rgba(255, 255, 255, 0.23) !important;
}