.container {
    display: flex;
    flex-direction: column;
    padding: 0 50px;
}

.container > div {
    background: var(--accentColor);
    margin: 50px 0;
    border-radius: 20px;
    padding: 10px 30px;
}

.container h3 {
    color: #fff;
    font-size:30px;
}

@media screen and (max-width: 1000px) {
    .container {
        background: var(--accentColor);
        padding: 0;
    }
    .container > div {
        padding: 0;
    }
}