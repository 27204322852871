.container {
    width: 100%;
    height: 120px;
    background: #9ad4e7;
    display: grid;
    grid-template-columns: 425px 1fr 100px;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}


.appLogo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.appLogo img {
    width: 45px;
}

.appLogo div {
    font-family: var(--accentFontFamily), cursive;
    font-size: 40px;
    margin-left: 15px;
    color: #295d96
}

.pages {
    justify-self: end;
    padding-right: 50px;
}
.pages a {
    margin-right: 30px;
    color: #fff;
    font-size: var(--headerSize);
}


@media screen and (max-width: 1000px) {
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
