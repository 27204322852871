.container {
    display: flex;
    flex-wrap: wrap;
    padding: var(--pagePadding);
}

h2 {
    width: 100%;
    text-align: center;
    margin: 20px;
    margin-right: 20vw;
    font-size: var(--headerSize);
    margin: var(--pageTitleMargins);
}

.possibilities {
    width: 80%;
    margin-left: 10%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.possibilities h4 {
    margin-left: 10px;
}

.possibilities h3 {
    margin-left: 50px;
}

@media screen and (max-width: 1000px) {
    .container {
        padding: 0;
    }
    .possibilities {
        width: calc( 100vw - 10px);
        margin-left: 5px;
    }

    .container textarea {
        width: 100%;
    }

    .container button {
        margin-top: 10px;
    }

}


/* .selectMode {
    position: relative;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}


.selectMode:before {
    position: absolute;
    content: '';
    right: 0;
    top: 10px;
    width: 2px;
    height: calc(100% - 20px);
    background: #ddd;
}

.modifyMode {
    width: 50%;
    padding-left: 5vw;
}

.selectMode>h3 {
    padding-left: 5vw;
    color: rgb(122, 12, 12);
}

.selectMode>div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 5vw;
}

.selectMode>div h4 {
    width: 100%;
}

.selectMode>div button {
    margin-left: 10px;
}

.modifyHistory {
    width: 70%;
    min-width: 300px;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 20px;
    align-items: center;
    justify-items: start;
    margin-top: 30px;
}

.modifyHistory>h2 {
    grid-column: 1/4;
}

.modifyHistory .confirm {
    margin-top: 20px;
}

.container textarea {
    width: 300px;
    height: 80px;
    border-radius: 3px;
    border: 1px solid #ccc;
}
 */
