.container {
   
}

.container th, .container td span {
    padding-left: 10px;
}

.container td, .container th {
    font-weight: 700;
    font-size: 20px;
}
