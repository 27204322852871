:root {
    --accentColor: #295c98;
    --lightAccentColor: #98d5e7;
    --accentFontFamily: "Dancing Script";
    --normalSize: 20px;
    --headerSize: 30px;
    --pagePadding: 0 20px;
    --pageTitleMargins: 40px 0;
}

* {
    font-size: var(--normalSize);
}

.Portal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Portal__con {
    width: 800px;
    height: 800px;
    background: #fff;
    padding: 50px;
    border-radius: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.Portal__con .Product {
    width: 100%;
    height: calc( 100% - 20px);
    margin: 0 0 !important;
}



.selectMode {
    width: 80vw;
    border: 20px solid var(--accentColor);
    border-radius: 20px;
    padding: 5px 20px 20px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.selectMode h4 {
    width: 100%;
}

.pointer {
    cursor: pointer;
}

table td:not(.pointer) {
    cursor: unset;
}

@media screen and (max-width: 1000px) {
    body {
        padding-bottom: 100px;
    }
}
